import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue'; // 首页
// import DetailPage from '../views/DetailPage.vue'; // 详情页放在views/目录下

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage // 首页
  },
  // {
  //   path: '/detail/:id',
  //   name: 'Detail',
  //   component: DetailPage, // 详情页
  //   props: true // 将路由参数传递给详情页
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
