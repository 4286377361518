<template>
  <div id="app">
    <!-- 这个地方放 router-view 来渲染当前匹配的路由页面 -->
    <router-view />
  </div>
</template>

<script setup>
</script>

<style scoped>
/* 这里可以放置全局样式 */
</style>
